<template>
  <v-container fluid class="mb-2">
    <h3 class="mt-4 mb-2">Hemodialisis (HD)</h3>
    <v-row align="center"
      justify="center">
      <v-col cols="12" lg="8" md="8" sm="8">
        <v-card color="teal darken-1 mt-1 mb-2" dark>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" centered style="font-size: 12px" class="mt-2">
      <v-tab>Pemantauan HD</v-tab>
      <v-tab>Observasi HD</v-tab>
      <v-tab>CPPT</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <PemantauanHd />
      </v-tab-item>
      <v-tab-item>
        <ObservasiHd />
      </v-tab-item>
      <v-tab-item>
        <Cppt />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Master from '../../data/form-demografi'
// eslint-disable-next-line no-unused-vars
import PemantauanHd from './PemantauanHd'
import Cppt from './Cppt'
// eslint-disable-next-line no-unused-vars
import ObservasiHd from './ObservasiHd'
import SmartWidget from '@/components/SmartWidget.vue'

// import SmartWidget from '@/components/SmartWidget.vue'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  components: {
    PemantauanHd,
    ObservasiHd,
    SmartWidget,
    Cppt

  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      snack_color: 'green',
      url_emr: 'http://emr.rs-syafira.com/',
      master: Master,
      tab: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: ''
        },
        inputan_terapi: {
          diagnosa: '',
          permintaan_terapi: ''
        }
      },
      user: JSON.parse(localStorage.getItem('user')),
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      // console.log(this.master
      this.getDataPasien(this.$route.query.no_rm)
    }
  },
  methods: {
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          console.log('HASIL RESPONSE ' + response)

          // this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
          // this.folioYangDipakai = JSON.parse(
          //   localStorage.getItem('folioIdYangDipakai')
          // )
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
