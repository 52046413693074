<!-- eslint-disable no-undef -->
<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col cols="12" md="12" lg="12" sm="12">
        <v-btn class="mb-2" @click="buatCppt()" color="primary"
          >Buat CPPT</v-btn
        >
        <v-simple-table
          class="table table-bordeless table-sm mt-3"
          width="100%"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="width: 10%; vertical-align: middle"
                  class="aksi-button text-center"
                >
                  TGL/JAM
                </th>
                <th
                  style="width: 20%; vertical-align: middle"
                  class="aksi-button text-center"
                >
                  PROFESI
                </th>
                <th
                  style="width: 30%; vertical-align: middle"
                  class="aksi-button text-center"
                >
                  SOAP
                </th>
                <th
                  style="width: 20%; vertical-align: middle"
                  class="aksi-button text-center"
                >
                  Intruksi Tenaga Kesehatan
                </th>
                <th
                  style="width: 20%; vertical-align: middle"
                  class="aksi-button text-center"
                >
                  Verifikasi DPJP
                </th>
              </tr>
            </thead>
            <tbody v-for="(itemSoap, indexSoap) of listSoap" :key="indexSoap">
              <tr>
                <td style="text-align: center; vertical-align: middle">
                  {{ itemSoap.created_at }}
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ itemSoap.profesi }}
                </td>
                <td>
                  <table class="table table-bordeless m-1" width="100%">
                    <tr>
                      <td style="vertical-align: middle" width="25%">
                        <b>S</b>
                      </td>
                      <td style="vertical-align: middle" width="5%">:</td>
                      <td style="vertical-align: middle; height: 100px">
                        <span v-html="itemSoap['subject']"></span>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: middle" width="25%">
                        <b>O</b>
                      </td>
                      <td style="vertical-align: middle" width="5%">:</td>
                      <td style="vertical-align: middle; height: 100px">
                        <span v-html="itemSoap['object']"></span>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: middle" width="25%">
                        <b>A</b>
                      </td>
                      <td style="vertical-align: middle" width="5%">:</td>
                      <td style="vertical-align: middle; height: 100px">
                        <span v-html="itemSoap['asesmen']"></span>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: middle" width="25%">
                        <b>P</b>
                      </td>
                      <td style="vertical-align: middle" width="5%">:</td>
                      <td style="vertical-align: middle; height: 100px">
                        <span v-html="itemSoap['perencanaan']"></span>
                      </td>
                    </tr>
                  </table>
                </td>
                <td style="text-align: center; vertical-align: middle">
                  <span v-html="itemSoap['instruksi']"></span>
                  <br />
                  <span>{{ itemSoap.verifikasi_dpjp }}</span>
                </td>

                <td style="text-align: center; vertical-align: middle">
                  dr. Faisal, Sp.Pd, FINASIM
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogCppt" :persistent="fixed" max-width="980">
      <v-card>
        <v-card-title class="headline"> CPPT </v-card-title>
        <v-card-text>
          <label class="mb-3">🟦 <b>Subject</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.subject"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Object</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.object"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Asesmen</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.asesmen"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Perencanaan</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.perencanaan"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Instruksi</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.instruksi"
            :config="editorConfig"
          ></ckeditor>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="simpan" color="primary" class="btn" small dark>Simpan</v-btn>
          <v-btn @click="tutup" class="btn" small color="red" dark>Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  errorMsg
} from '../../plugins/supports'
export default {
  components: {},

  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      pemakai: '',
      dataPatient: '',
      folioYangDipakai: '',
      showDialogCppt: false,
      fixed: true,
      search: '',
      no_rm: '',
      cpptLast: {
        subject: '',
        object: '',
        perencanaan: '',
        asesmen: '',
        instruksi: '',
        tanggal: ''
      },
      content: '',
      config: {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
        ],
        height: 250
      },
      headers: [
        {
          text: 'Aksi',
          align: 'start',
          value: 'aksi'
        },
        {
          text: 'DPJP/PPA Lainya',
          align: 'start',
          value: 'verifikasi_dpjp'
        },
        { text: 'Tanggal Dibuat', value: 'created_at' },
        { text: 'No. Register', value: 'no_reg' }
      ],
      listSoap: [],
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },

  methods: {
    liatSoap (item) {
      console.log(item)
      this.cpptLast.subject = item.subject
      this.cpptLast.object = item.object
      this.cpptLast.asesmen = item.asesmen
      this.cpptLast.instruksi = item.instruksi
      this.cpptLast.perencanaan = item.perencanaan
      this.cpptLast.tanggal = item.created_at
    },
    getListSoap () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/list-cppt?rm=' + this.$route.query.no_rm,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          console.log(results)
          // eslint-disable-next-line no-unused-vars
          const listSoap = []
          if (con) {
            // eslint-disable-next-line no-unused-vars
            for (const item of results) {
              listSoap.push({
                aksi: item.id_emr_cppt,
                verifikasi_dpjp: item.verifikasi_dpjp,
                created_at: item.created_at,
                no_reg: item.no_reg,
                subject: item.subject,
                profesi: item.profesi,
                object: item.object,
                asesmen: item.asesmen,
                perencanaan: item.perencanaan,
                instruksi: item.instruksi,
                petugas_1: item.petugas_1,
                petugas_2: item.petugas_2,
                is_double_check: item.is_double_check
              })
            }
          } else {
          }

          this.listSoap = listSoap
        },
        'JSON'
      )
    },
    getDataSoap () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/last-cppt?rm=' + this.$route.query.no_rm,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            console.log(results)
            // this.cpptLast.subject = results.subject
            // this.cpptLast.object = results.object
            // this.cpptLast.asesmen = results.asesmen
            // this.cpptLast.intruksi = results.instruksi
            // this.cpptLast.perencanaan = results.perencanaan
            // this.cpptLast.tanggal = results.created_at
          } else {
            errorMsg('Belum Ada CPPT')
          }
        },
        'JSON'
      )
    },
    tutup () {
      this.showDialogCppt = false
      this.fixed = false
    },
    onFocus (editor) {
      console.log(editor)
    },
    buatCppt () {
      this.showDialogCppt = true
    },
    simpan () {
      // eslint-disable-next-line no-undef

      var createdby = this.pemakai.employee_id
      var namaPembuat = this.pemakai.employee_name
      var namaPasien = this.dataPatient.nama_pasien
      var norm = this.$route.query.no_rm
      var noreg = this.$route.query.registration_id
      var folioid = this.$route.query.folio_id
      const formData = new FormData()
      console.log(formData)

      var data = {
        cpptLast: this.cpptLast,
        namaPembuat: namaPembuat,
        createdby: createdby,
        namaPasien: namaPasien,
        norm: norm,
        noreg: noreg,
        folioid: folioid,
        verifikasi: this.pegawai.employee_name,
        type: 'rawat-jalan'

      }

      // eslint-disable-next-line no-undef
      $.post(baseUrl + 'cppt/simpan-cppt', data, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          successMsg(msg)
        } else {
          errorMsg(msg)
        }
      }, 'JSON')

      console.log(data)
    }
  },
  created () {
    // console.log('DARI PROPS' + this.memberCard)
    // setTimeout(() => {
    //   this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
    //   this.folioYangDipakai = JSON.parse(
    //     localStorage.getItem('folioIdYangDipakai')
    //   )
    if (this.$route.query.no_rm != null) {
      this.getDataSoap()
      this.getListSoap()
      this.pegawai = JSON.parse(localStorage.getItem('user'))
    }
    // if (!localStorage.getItem('reload')) {
    //   localStorage.setItem('reload', true)
    //   location.reload()
    // } else {
    //   localStorage.removeItem('reload')
    // }
    // location.href

    this.pemakai = JSON.parse(localStorage.getItem('user'))
    // }, 1000)
  }
}
</script>
