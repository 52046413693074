<template>
  <v-container fluid class="mb-2">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <div class="table-scroll mt-2">
          <div class="table-wrap">
            <table class="table table-sm table-bordered observasi-hd">
              <thead>
                <tr>
                  <th
                    width="150px"
                    class="text-center aksi-button"
                    rowspan="2"
                    style="padding-top: 35px"
                  >
                    Item HD
                  </th>
                  <th
                    width="120px"
                    rowspan="2"
                    style="padding-top: 35px; text-align: center"
                  >
                    Pre-HD
                  </th>
                  <th colspan="8" class="text-center">Pemantauan Jam Ke-</th>
                  <th
                    width="109px"
                    rowspan="2"
                    style="padding-top: 35px; text-align: center"
                  >
                    Post-HD
                  </th>
                </tr>
                <tr>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    1
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    2
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    3
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    4
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    5
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    6
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    7
                  </th>
                  <th
                    width="10%"
                    class="text-center aksi-button"
                    style="vertical-align: middle"
                  >
                    8
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="(itemMaster, indexMaster) of hd.master"
                :key="indexMaster"
              >
                <tr class="baris">
                  <td style="vertical-align: middle">
                    {{ itemMaster.value }}
                    <!-- {{ hd.master.length }} -->
                    <!-- {{ i == 13 ? itemMaster.pemantauan[8] = user.employee_name : '' }} -->
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pre_hd"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[1]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[2]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[3]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[4]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[5]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[6]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[7]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.pemantauan[8]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="vertical-align: middle"
                      v-model="itemMaster.post_hd"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <table class="table table-md table-bordered perencanaan-pulang">
          <thead class="table-warning">
            <tr>
              <th colspan="2">PERENCANAAN PULANG <i>DISCHARGE PLANNING</i></th>
            </tr>
          </thead>
          <tbody
            v-for="(
              itemPerencanaanPulang, indexPerencanaan
            ) of hd.perencanaan_pulang"
            :key="indexPerencanaan"
          >
            <tr>
              <td width="50%">{{ itemPerencanaanPulang.value }}</td>
              <td width="50%">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="itemPerencanaanPulang.input"
                  :placeholder="itemPerencanaanPulang.value"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <table class="table table-md">
          <thead>
            <tr>
              <td>
                Perawat
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </td>
              <td>
                Dokter
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                (<b>dr. Faisal, Sp.Pd, FINASIM</b>)
              </td>
            </tr>
          </thead>
        </table>
        <v-btn class="mt-2 btn-block" @click="simpanOberservasi" color="primary"
          >Simpan Oberservasi</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  data () {
    return {
      column: null,
      observasiHd: null,
      user: JSON.parse(localStorage.getItem('user')),
      hd: ''
    }
  },
  created () {
    this.getDataObservasiHd()

    console.log(this.user + ' User')
  },
  methods: {
    simpanOberservasi () {
      // eslint-disable-next-line no-unused-vars
      var data = {
        hd: this.hd,
        no_rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        registration_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.registration_id,
        created_by: this.user.employee_id
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/simpan-observasi-hd',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            setTimeout(() => {}, 1000)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    getDataObservasiHd () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'cppt/get-data-observasi-hd',
        { folio_id: this.$route.query.folio_id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.hd = results
            this.setData()
          } else {
            this.hd = {
              master: {
                jam: {
                  value: 'Jam (WIB)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                keluhan: {
                  value: 'Keluhan',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                bb: {
                  value: 'BB (Kg)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                kesadaran: {
                  value: 'Kesadaran',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                tekanan_darah: {
                  value: 'Tekanan Darah (mmHg)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                napas: {
                  value: 'Napas Nadi (Menit)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                suhu: {
                  value: 'Suhu (°C)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                qb: {
                  value: 'Qb (mmenit)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                qd: {
                  value: 'Qd (mmenit)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                tekanan: {
                  value: 'Tekanan Vena (mmHg)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                tmp: {
                  value: 'TMP (mmHg)',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                volume: {
                  value: 'Volume yang ditarik',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                asesmen: {
                  value: 'Asesmen Intervensi Keterangan',
                  pre_hd: '',
                  pemantauan: {
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                  },
                  post_hd: ''
                },
                nama: {
                  value: 'Nama dan Parah Perawat',
                  pre_hd: this.user.employee_name,
                  pemantauan: {
                    1: this.user.employee_name,
                    2: this.user.employee_name,
                    3: this.user.employee_name,
                    4: this.user.employee_name,
                    5: this.user.employee_name,
                    6: this.user.employee_name,
                    7: this.user.employee_name,
                    8: this.user.employee_name
                  },
                  post_hd: this.user.employee_name
                }
              },
              perencanaan_pulang: {
                hari: {
                  value: 'Hari dan Tanggal',
                  input: ''
                },
                edukasi: {
                  value: 'Edukasi',
                  input: ''
                },
                rencana: {
                  value: 'Rencana Konsultasi',
                  input: ''
                },
                rencana_pemeriksaan: {
                  value: 'Rencana Pemeriksaan Penunjang',
                  input: ''
                },
                lain_lain: {
                  value: 'Lain-Lain',
                  input: ''
                }
              }
            }
            this.setData()
          }
        },
        'JSON'
      )
      // for (const item of this.hd.master) {
      //   console.log(item)
      // }
    },
    setData () {
      var today = new Date()
      var date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1 < 10
          ? '0' + (today.getMonth() + 1)
          : today.getMonth() + 1) +
        '-' +
        today.getDate()
      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      var datetime = date + ' ' + time
      var nama = this.user.employee_name
      this.hd.perencanaan_pulang.hari.input = datetime
      // eslint-disable-next-line no-undef
      // var id = $('.baris:eq(13)')
      // console.log(id)
      // this.hd.master.jam.pemantauan[8] = nama
      console.log(datetime)
      console.log(nama)
    }
  }
}
</script>

<style>
/* tbody {
        font-size: 20px;
    } */

form,
.form-group {
  margin-bottom: 0.2rem !important;
}

.modal-xl {
  max-width: 100% !important;
}

/* label:hover,
    input:hover {
        font-size: 25px;
    } */

.observasi-hd {
  border-collapse: collapse;
}

.observasi-hd tr th {
  border: 1px solid black;
}

.observasi-hd tr td {
  border: 1px solid black;
}

.perencanaan-pulang {
  border-collapse: collapse;
}

.perencanaan-pulang tr th {
  border: 1px solid black;
}

.perencanaan-pulang tr td {
  border: 1px solid black;
}
table,
.aksi-button {
  table-layout: fixed;
  width: 250px;
}
.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-scroll {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  background: #fff;
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden;
}

.clone td,
.clone th {
  border-color: transparent;
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  background: #eee;
  visibility: visible;
}

.clone thead,
.clone tfoot {
  background: transparent;
}
.float-group {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
