<template>
  <v-container fluid class="mb-2">
    <v-row align="center"
      justify="center" class="mt-2">
      <v-col cols="12" lg="9" sm="9" md="9">
        <div class="form-group">
          <div
            style="z-index: 999999"
            class="float-group btn-group-vertical"
            role="group"
            aria-label="Vertical button group"
          >
            <v-btn
              class="mt-2 btn-block"
              v-if="show"
              @click="simpanPemantauanHd()"
              color="primary"
              >Simpan Pemantauan HD</v-btn
            >
          </div>
        </div>
        <v-radio-group dense v-model="pemantauan.gelang_identitas" row>
          <template v-slot:label>
            <div><b>Gelang Identitas</b></div>
          </template>
          <v-radio label="Sudah Terpasang" value="Sudah Terpasang"></v-radio>
          <v-radio label="Belum Terpasang" value="Belum Terpasang"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.alergi" row>
          <template v-slot:label>
            <div><b>Alergi</b></div>
          </template>
          <v-radio label="Ya" value="Ya"></v-radio>
          <v-radio label="Tidak" value="Tidak"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.gelang_alergi" row>
          <template v-slot:label>
            <div><b>Gelang Alergi</b></div>
          </template>
          <v-radio label="Sudah Terpasang" value="Sudah Terpasang"></v-radio>
          <v-radio label="Belum Terpasang" value="Belum Terpasang"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.akses_vascular_jenis" row>
          <template v-slot:label>
            <div><b>Akses Vascular Jenis</b></div>
          </template>
          <v-radio
            label="Fistula AV(Cimino)"
            value="Fistula AV(Cimino)"
          ></v-radio>
          <v-radio label="Tunnel Catheter" value="Tunnel Catheter"></v-radio>
          <v-radio label="Femoral" value="Femoral"></v-radio>
          <v-radio label="Graft AV" value="Graft AV"></v-radio>
          <v-radio
            label="Double Lumen Catheter"
            value="Double Lumen Catheter"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          label="Lokasi"
          v-model="pemantauan.lokasi"
          placeholder="Lokasi"
        ></v-text-field>
        <v-radio-group dense v-model="pemantauan.tandaInfeksi" row>
          <template v-slot:label>
            <div><b>Tanda Infeksi</b></div>
          </template>
          <v-radio label="Ya" value="Ya"></v-radio>
          <v-radio label="Tidak" value="Tidak"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.aneurisma" row>
          <template v-slot:label>
            <div><b>Aneurisma</b></div>
          </template>
          <v-radio label="Ya" value="Ya"></v-radio>
          <v-radio label="Tidak" value="Tidak"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.thrill" row>
          <template v-slot:label>
            <div><b>Thrill (AV Fistula)</b></div>
          </template>
          <v-radio label="Lemah" value="Lemah"></v-radio>
          <v-radio label="Kuat" value="Kuat"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.bruit" row>
          <template v-slot:label>
            <div><b>Bruit (AV Fistula)</b></div>
          </template>
          <v-radio label="Lemah" value="Lemah"></v-radio>
          <v-radio label="Kuat" value="Kuat"></v-radio>
        </v-radio-group>
        <v-text-field
          label="Lain-Lain"
          v-model="pemantauan.lainLain"
          placeholder="Lain-Lain"
        ></v-text-field>
        <v-radio-group dense v-model="pemantauan.mesin" row>
          <template v-slot:label>
            <div><b>Mesin HD</b></div>
          </template>
          <v-radio label="Fresenius" value="Fresenius"></v-radio>
          <v-radio label="B-Braun" value="B-Braun"></v-radio>
        </v-radio-group>
        <v-text-field
          label="No. Mesin"
          v-model="pemantauan.noMesin"
          placeholder="No. Mesin"
        ></v-text-field>
        <v-radio-group dense v-model="pemantauan.dialisat" row>
          <template v-slot:label>
            <div><b>Dialisat</b></div>
          </template>
          <v-radio
            label="High Calcium (CA > 1.3 mmol/L)"
            value="High Calcium (CA > 1.3 mmol/L)"
          ></v-radio>
          <v-radio
            label="Low Calcium (CA < 1.3 mmol/L)"
            value="Low Calcium (CA < 1.3 mmol/L)"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          label="Suhu °C"
          v-model="pemantauan.suhu"
          placeholder="Suhu"
        ></v-text-field>
        <v-text-field
          label="Model"
          v-model="pemantauan.model"
          placeholder="Model"
        ></v-text-field>
        <v-radio-group dense v-model="pemantauan.dialiser" row>
          <template v-slot:label>
            <div><b>Dialiser</b></div>
          </template>
          <v-radio label="Low Fluw" value="Low Fluw"></v-radio>
          <v-radio label="High Low" value="High Low"></v-radio>
        </v-radio-group>
        <v-radio-group dense v-model="pemantauan.dialise" row>
          <template v-slot:label>
            <div><b>Dialise</b></div>
          </template>
          <v-radio label="Baru" value="Baru"></v-radio>
          <v-radio label="Reuse" value="Reuse"></v-radio>
        </v-radio-group>
        <v-text-field
          label="Bb Kering"
          v-model="pemantauan.bbKering"
          placeholder="Bb Kering"
        ></v-text-field>
        <v-radio-group dense v-model="pemantauan.resepHD" row>
          <template v-slot:label>
            <div><b>Dialise</b></div>
          </template>
          <v-radio label="Ada" value="Ada"></v-radio>
          <v-radio label="Tidak Ada" value="Tidak Ada"></v-radio>
        </v-radio-group>
        <v-text-field
          label="Lama HD"
          v-model="pemantauan.lamaHDJam"
          placeholder="Lama HD"
        ></v-text-field>
        <v-text-field
          label="LBlood Flow Rate (QB) ml/menit"
          v-model="pemantauan.lBlood"
          placeholder="LBlood Flow Rate (QB) ml/menit"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  data () {
    return {
      column: null,
      show: false,
      pemantauan: {
        gelang_identitas: '',
        alergi: '',
        gelang_alergi: '',
        akses_vascular_jenis: '',
        lokasi: '',
        tandaInfeksi: '',
        aneurisma: '',
        thrill: '',
        bruit: '',
        lainLain: '',
        mesin: '',
        noMesin: '',
        dialisat: '',
        suhu: '',
        model: '',
        dialiser: '',
        dialise: '',
        bbKering: '',
        resepHD: '',
        lamaHDJam: '',
        lBlood: ''
      },
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  created () {
    // this.detail_program = []
    localStorage.removeItem('isProgramChange')
    if (this.$route.query.no_rm != null) {
      // console.log(this.master
      this.getData()
      this.show = true
    }
  },
  methods: {
    getData () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'cppt/get-pemantauan-hd',
        { folio_id: this.$route.query.folio_id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.pemantauan = results.pemantauan
          } else {

          }
        },
        'JSON'
      )
    },
    simpanPemantauanHd () {
      var data = {
        pemantauan: this.pemantauan,
        no_rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        registration_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.registration_id,
        created_by: this.user.employee_id
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/simpan-pemantauan-hd',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            successMsg(msg)
            setTimeout(() => {
              this.getData()
            }, 1000)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      console.log(data)
    }
  }
}
</script>
